import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { ToastrService } from 'ngx-toastr';
import { LocalServiceService } from '../services/local-service';
import { ChangeBehavoiorCssService } from '../services/change-behavoior-css';
import { ApisService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataSharingService } from '../services/data-sharing.service';
import { NotificationService } from '../services/notification.service';
import { LoginDataService } from '../services/login-data.service';
import { SelectsidebarService } from '../services/selectsidebar.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public sidebarShow: boolean = false;
  showNavbar: boolean = true;
  showNavbar1: boolean = true;

  hideNavbar: boolean = false;
  user: any;
  notifyBadge = false;
  notifications = [];
  isLogedin: boolean = false;
  resettoken: any = true;
  param1: any;
  currentUrl: string = '';
  resetheader: boolean = false;
  public isMenuCollapsed = true;
  page = 1;
  pageSize = 3;
  notification: any;
  token: any;
  userProfile: any;
  nationality: any;
  profilePic: any;
  isRead: any;
  filterData: any;
  img: any;
  base_url = `https://www.fitfortravel.nhs.uk/destinations.aspx?from=feature`;
  opened: any = false;
  isUserLoggedIn: any;
  userProfileType: any;
  singledata2: any;
  closeModal: any;
  result: any;
  locationData: any;
  role: any;
  notifData: any;
  signOutData: any;

  constructor(
    public router: Router,
    private apiService: ApisService,
    private activatedRoute: ActivatedRoute,
    private jwtService: JwtService,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private LocalServiceService: LocalServiceService,
    private changeBehavoiorCssService: ChangeBehavoiorCssService,
    private DataSharingService: DataSharingService,
    private NotificationService: NotificationService,
    private LoginDataService: LoginDataService,
    private modalService: NgbModal,
    private SelectsidebarService: SelectsidebarService
  ) {}

  ngOnInit(): void {
    this.notifData = localStorage.getItem('token');
    if (localStorage.getItem('isLoggedin')) {
      this.isLogedin = true;
    } else {
      this.isLogedin = false;
    }
    // get data from myprofile component
    this.role = localStorage.getItem('role');
    this.DataSharingService.data.subscribe((ele: any) => {
      this.img = ele?.userProfile?.img;
      this.userProfile = ele.userProfile;
      this.profilePic = `${ele?.user_bP}${this.userProfile?.img}`;
    });

    this.LoginDataService.data.subscribe((ele: any) => {
      this.img = ele?.userProfile?.img;
      this.userProfile = ele.userProfile;
      this.profilePic = `${ele?.user_bP}${this.userProfile?.img}`;
    });

    this.NotificationService.data.subscribe((ele: any) => {
      this.notification = ele.notif;
      this.filterData = this.notification?.filter(
        (art: any) => art.isRead === 0
      );
    });

    if (this.notifData !== null) {
      this.getNotificationData(this.page, this.pageSize, -1, '');
      this.getInitialData();
    }

    this.router.events.subscribe((val) => {
      // if (val.constructor.name === 'NavigationStart') {
      if (localStorage.getItem('isLoggedin')) {
        this.isLogedin = true;
      }
      //  }
      this.token = this.LocalServiceService.getData('token');
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        let path = this.currentUrl.split('?');

        if (path[0] == '/reset-password') {
          this.resetheader = true;
        }
      }
    });
  }

  Back() {
    this.router.navigate(['/']);
    this.LocalServiceService.removeData('token');
  }

  // Back1() {
  //   this.router.navigate(['/dashboard']);
  // }

  routedata() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      let data = queryParams['token'];
    });
  }
  onLoggedout() {
    this.signOut();
    // this.jwtService.logoutUser();
  }
  collapseNavbar() {
    this.showNavbar = !this.showNavbar;
    this.showNavbar1 = true;
  }
  collapseNavbar1() {
    this.showNavbar1 = !this.showNavbar1;
    this.showNavbar = true;
  }
  hideNav() {
    this.showNavbar = true;
    this.showNavbar1 = true;
    // alert('test')
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.showPopup(2, event);
    event.stopPropagation();
  }

  showPopup(id: any, event: any) {
    if (id == 1) {
      this.opened = true;
      event.stopPropagation();
    } else {
      this.opened = false;
    }
  }

  showNotificationData() {
    if (this.notification?.length > 0) {
      this.isRead = this.readNotificationData();
      if (this.notifData !== null) {
        this.getNotificationData(this.page, this.pageSize, -1, '');
      }

      this.reloadComponent();
    }
  }

  //get notification

  getNotificationData(
    page: number,
    pageSize: number,
    dir: number,
    searchKey: string
  ) {
    this.SpinnerService.show();

    this.apiService
      .getNotification(page, pageSize, dir, searchKey)
      .subscribe((res) => {
        this.notification = res.responseData?.notif;
        this.filterData = this.notification?.filter(
          (art: any) => art.isRead === 0
        );

        this.SpinnerService.hide();
      });
  }

  readNotificationData() {
    this.apiService.readNotification(this.token).subscribe((res) => {});
  }

  // get user profile image

  getInitialData() {
    this.apiService.getUserProfile().subscribe((res) => {
      this.userProfile = res.responseData?.userProfile;
      this.img = res.responseData?.userProfile.img;
      this.userProfileType = res.responseData?.userProfile.userType;
      this.profilePic = `${res.responseData?.user_bP}${this.userProfile?.img}`;
      this.SpinnerService.hide();
    });
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboard']);
  }

  GoToDetails() {
    this.role = localStorage.getItem('role');
    if (this.role == 'Individual') {
      this.router.navigate(['/dashboard']);
    }
    if (this.role == 'Company') {
      this.router.navigate(['/home'], { skipLocationChange: true });
    }
    if (this.role == 2) {
      this.router.navigate(['/home'], { skipLocationChange: true });
    }
    if (this.role == 1) {
      this.router.navigate(['/dashboard']);
    }
  }

  triggerModal4(content: any, data: any) {
    this.singledata2 = data;
    this.locationData = data;
    this.modalService.open(content, { centered: true }).result.then(
      (res) => {
        this.closeModal = `Closed with: ${res}`;
      },
      (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  GotoLiveUrl() {
    window.location.href = this.base_url;
  }

  ConfirmnextDose(data: any) {
    this.GotoLiveUrl();
  }

  goToHome() {
    this.router.navigate(['/']);
    this.SelectsidebarService.updatedDataOnSelect(1);
  }

  signOut() {
    let data = {
      accessToken: localStorage.getItem('token'),
    };
    this.apiService.logout(data).subscribe(
      (res: any) => {
        if (res.statusCode === 0) {
          this.signOutData = res;
        } else {
          if (res.statusCode == 1) {
            this.signOutData = res;
            window.localStorage.clear();
            window.sessionStorage.clear();
            this.changeBehavoiorCssService.updatedDataSelection(1);
            this.isLogedin = false;
            this.toastr.success('Sign out successful');
            this.isMenuCollapsed = true;
            this.router.navigate(['/login']);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
