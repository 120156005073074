import { Injectable } from '@angular/core';
import { ApiService } from './api.httpservice';

@Injectable({
  providedIn: 'root',
})
export class ApisService {
  public count = 1;

  constructor(private apiService: ApiService) {}

  login(data: any) {
    return this.apiService.post(`user/login`, data);
  }

  logout(data: any) {
    return this.apiService.post(`user/logout`, data);
  }

  signup(data: any) {
    return this.apiService.post(`user/signup`, data);
  }

  forgetPassword(data: any) {
    return this.apiService.post(`user/forget-password`, data);
  }

  resetPassword(data: any) {
    return this.apiService.post(`user/reset-password`, data);
  }

  forgotPassword(data: any) {
    return this.apiService.post(`user/forget-password`, data);
  }

  getVaccineList() {
    return this.apiService.get(`vaccine/all-vaccines-list`);
  }

  getVaccineById(diseaseId: string) {
    return this.apiService.get(`vaccine?diseaseId=${diseaseId}`);
  }

  AddnextDate(data: any) {
    return this.apiService.post(`vaccine/create-vaccine`, data);
  }

  uploadVaccineimge(data: any) {
    return this.apiService.post(`vaccine/upload-vaccine-images`, data);
  }

  getMyVaccine() {
    return this.apiService.get(`vaccine/get-my-vaccines`);
  }

  getVaccineDetails(id: any) {
    return this.apiService.get(`vaccine/vaccine-details?vaccineId=` + id);
  }

  deleteVaccineDose(data: any) {
    return this.apiService.post(`vaccine/delete-vaccine`, data);
  }

  updateVaccinedetails(data: any) {
    return this.apiService.put(`vaccine/update-vaccine`, data);
  }

  getcurrentData(id: any) {
    return this.apiService.get(`vaccine/vaccine-details?vaccineId=` + id);
  }

  updateUser(data: any) {
    return this.apiService.put(`user/update`, data);
  }

  socialUpdateUser(data: any) {
    return this.apiService.put(`user/update`, data);
  }

  sociallogin(data: any) {
    return this.apiService.post(`user/social-login`, data);
  }

  linkedinlogin(data: any) {
    return this.apiService.post(`user/login/linkedin/code`, data);
  }

  getnewVaccineList() {
    return this.apiService.get(`vaccine/list`);
  }

  getPaginatedVaccineList(
    page: number,
    limit: number,
    dir: number,
    skey: string,
    type?: number
  ) {
    return this.apiService.get(
      `vaccine/list?limit=${limit}&dir=${dir}&page=${page}&sKey=${skey}&type=${type}`
    );
  }

  getPaginatedVaccineListOfMyVaccineRecord(
    page: number,
    limit: number,
    dir: number,
    skey: string,
    type?: number
  ) {
    return this.apiService.get(
      `vaccine/doses/list?limit=${limit}&dir=${dir}&page=${page}&sKey=${skey}&type=${type}`
    );
  }

  getPinnedVaccineList(limit: number) {
    return this.apiService.get(`vaccine/list?limit=${limit}&isPinned=${true}`);
  }

  getPinnedVaccineListinSequence(limit: number) {
    return this.apiService.get(
      `vaccine/doses/list?limit=${limit}&isPinned=${true}`
    );
  }
  getVaccineListWithType(data: number) {
    return this.apiService.get(`vaccine/list?type=` + data);
  }

  pinUnpinVaccine(data: any) {
    return this.apiService.post(`vaccine/pin-vaccine`, data);
  }

  getProfileData(userId: any, diseaseId: any) {
    return this.apiService.get('/user/' + userId + '/disease/' + diseaseId);
  }

  getPdfData(data: any) {
    return this.apiService.post(`vaccine/pdf`, data);
  }

  deleteVaccineCard(diseaseId: any) {
    return this.apiService.delete('vaccine/disease/' + diseaseId);
  }

  deleteotherVaccineCard(diseaseId: any) {
    return this.apiService.delete('vaccine/other/' + diseaseId);
  }

  singleVaccineDose(diseaseId: any) {
    return this.apiService.get('vaccine?diseaseId=' + diseaseId);
  }

  dropDownDiseaseList() {
    return this.apiService.get(`vaccine/disease/list`);
  }

  deletePdf(data: any) {
    return this.apiService.deleteWithReqBody(`vaccine/pdf`, data);
  }

  getVideoList(page: number, limit: number, dir: number, skey: string) {
    return this.apiService.get(
      `content/video/list?limit=${limit}&dir=${dir}&page=${page}&sKey=${skey}`
    );
  }

  getVideoListforSearch(skey: string) {
    return this.apiService.get(`content/video/list?sKey=${skey}`);
  }

  updateDiseaseName(data: any) {
    return this.apiService.put(`vaccine/disease`, data);
  }

  uploadVaccineCertificate(data: any) {
    return this.apiService.put(`vaccine/certificate`, data);
  }

  deleteCertificate(data: any) {
    return this.apiService.deleteWithReqBody(`vaccine/certificate`, data);
  }

  createEmptyDisease(data: any) {
    return this.apiService.postWithApplicationJson(
      `vaccine/add/empty/disease`,
      data
    );
  }

  getEmptyDiseaseList() {
    return this.apiService.get(`vaccine/empty/disease/list`);
  }

  // Update Profile

  updateProfile(data: any) {
    return this.apiService.put('user/profile', data);
  }
  // get User Profile

  getUserProfile() {
    return this.apiService.get('user/profile');
  }
  // activate and deactivate user account

  activateAndDeactivateAccount(data: any) {
    return this.apiService.patch('user/active/deactive', data);
  }
  // Delete User user account

  deleteUserAccount() {
    return this.apiService.delete('user/account/delete');
  }

  // change User Account password

  changePassword(data: any) {
    return this.apiService.put('user/change/password', data);
  }
  // get  notification

  getNotification(page: number, limit: number, dir: number, skey: string) {
    return this.apiService.get(
      `notification?limit=${limit}&dir=${dir}&page=${page}&sKey=${skey}`
    );
  }
  // read notification
  readNotification(data: any) {
    return this.apiService.post('notification/read', data);
  }

  getGooglePlacesApiData(text: any, lat: number, long: number) {
    return this.apiService.get(
      `user/google/places?text=${text}&lat=${lat}&long=${long}&radius=2000`
    );
  }
}
