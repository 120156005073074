<footer>
  <div class="container footer-wrapper">
    <div class="footer-copyright py-3 ml-md-3 ml-0">
      <div class="d-flex flex-row justify-content-between">
        <div class="text-center text-md-left">
          <a href="javascript:void(0)">
            Copyright @ {{ date | date : "yyyy" }} Travel Health Companion. All
            rights reserved
          </a>
        </div>
        <div class="text-center text-md-right">
          <ul class="list-unstyled mb-0">
            <li class="list-inline-item">
              <a [routerLink]="['/about-us']"> About Us </a>
            </li>
            <li class="list-inline-item" *ngIf="token !== null">
              <a [routerLink]="['/settings']"> Settings </a>
            </li>
            <li class="list-inline-item">
              <a [routerLink]="['/help']"> Help </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
