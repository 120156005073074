import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectsidebarService {
  private dataSource = new BehaviorSubject<any>('');
  data = this.dataSource.asObservable();

  constructor() {}

  updatedDataOnSelect(data: any) {
    this.dataSource.next(data);
  }
}
