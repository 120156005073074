import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { JwtService } from '../services/jwt.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}
  authHeader: any;
  setUser() {
    if (localStorage.getItem('token') !== null) {
      this.authHeader = this.jwtService.getToken();
    } else {
      this.authHeader = localStorage.getItem('accessToken');
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.setUser();

    if (this.authHeader === null) {
      return next.handle(req.clone());
    }

    if (
      req.url ==
      'https://stagapi.travel-companion.co.uk/api/v1/user/6115152df4e89e695d13a91b/disease/60d570b40cf3d2141520fcd1'
    ) {
      const clonedReq = req.clone({ headers: req.headers.set('', ``) });
      return next.handle(clonedReq);
    }

    const clonedReq = req.clone({
      headers: req.headers.set('accessToken', `${this.authHeader}`),
    });

    return next.handle(clonedReq);
  }
}
