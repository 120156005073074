import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';

import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appDubleClick]',
})
export class DubleClickDirective {
  @Input() debounceTime = 400;
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: any;

  constructor() {}

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(debounceTime(this.debounceTime))
      .subscribe((e) => this.debounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
