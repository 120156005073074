import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  private dataSource = new BehaviorSubject<any>('');
  data = this.dataSource.asObservable();

constructor() { }

updatedDataSelectionuserProfile(data: any){
 
  this.dataSource.next(data);
 
}

sendNotificationData(data:any){
  this.dataSource.next(data);
}


}
