<div class="content-wrapper">
    <div class="container">
      <div class="wrap_first createaccount">
            <div class="dashboard-tiles-wrapper mxw-100 w-100 pt-0">
                <h1 class="page-heading mb-4">Privacy Policy</h1>
                <div class="pt-md-5 pt-3 text-gray">
                   <h3 class="mb-5 text-left text-black sub-heading">A mobile application for travellers to take care of their health, before and during a trip.</h3>
                   <p>Antium dolore mque laudan tium, totam rem aperiam, eaque ipsa quae ab illo inve ntore veritatis et qarc hitecto beatae vitae dicta sunt explicabo.</p>
                   <p>Nemo enim ipsam voluptatem quia vol uptas sit aspern atur aut odit aut fugit, sed quia conseq uu ntur magni dolores eos qui rat ione volu ptatem sequi nesciunt. Neque porro quis quam est, qui dolorem ipsum quia dolor sit amet, conse ctetur, adi pisci velit, sed qui dolorem ipsum quia dolor sit amet, conse ctetur. Dui dolorem ipsum dolor sit amet, conse ctetur, adi pisci velit, sed qui dolorem ipsum quia dolor sit amet, conse ctetur.</p>
                   <p>Dui dolorem ipsum quia dolor sit amet, conse ctetur, adi pisci velit, sed qui dolorem ipsum quia dolor sit amet, conse ctetur. Dui dolorem ipsum dolor sit amet, conse ctetur, adi pisci velit, sed qui dolorem ipsum quia dolor sit amet, conse ctetur.</p>
                </div>
            </div>
        </div>
    </div>
</div>