<div class="content-wrapper">
  <div class="container">
    <div class="wrap_first createaccount">
      <div class="dashboard-tiles-wrapper mxw-100 w-100 pt-0">
        <h1 class="page-heading mb-4">My Profile</h1>
        <div
          class="pt-md-5 pt-3"
          [ngClass]="!editProfile ? 'remove-input' : ''"
        >
          <div class="row">
            <!-- <div class="col-md-12 d-flex justify-content-end">
                        <div class="profile-upload">
                            <img *ngIf = "getpath === undefined" src="{{profilePic}}" onerror="this.src='assets/img/user.png'"  class="prf-ico" alt="">
                            <img *ngIf = "getpath !== undefined" src="{{profilePic1}}"   class="prf-ico" alt="">
                            <span>
                                <input type="file" class="form-control" accept="image/*"  (change)="changeProfilePic($event)"/>
                                <img src="assets/img/camera-icon.svg" alt="pin" />
                            </span>
                        </div>
                    </div> -->
            <!-- <p *ngIf = "pdferror === true" class="text-danger text-center col-md-12 mb-0 mt-4">The specified file sample-file.pdf could not be uploaded.<br>
                    Only files with the following extension are allowed : gif png jpg jpeg</p> -->
            <div class="col-md-12 profile-main mt-md-5 mt-3">
              <div class="row">
                <!-- <div class="col-md-6">
                                <div class="form-group mb-3 pb-md-3">
                                    <label class="mb-1">First Name</label>
                                    <input type="text" value="Maxwell D’souza" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')"  [(ngModel)]="userProfile.firstName"/>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mb-3 pb-md-3">
                                    <label class="mb-1">Last Name</label>
                                    <input type="text" value="Maxwell D’souza" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')"  [(ngModel)]="userProfile.lastName"/>

                                </div>
                            </div> -->
                <div class="col-md-6">
                  <div class="form-group mb-3 pb-md-3">
                    <label class="mb-1">Email</label>
                    <input
                      type="text"
                      value="maxwell@gmail.com"
                      class="form-control"
                      oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')"
                      [(ngModel)]="userProfile.email"
                      disabled
                    />
                  </div>
                </div>
                <div *ngIf="userProfile.code !== undefined" class="col-md-6">
                  <div class="form-group mb-3 pb-md-3">
                    <label class="mb-1">Organisation Code</label>
                    <input
                      type="text"
                      oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')"
                      readonly
                      value="101010"
                      class="form-control"
                      [(ngModel)]="userProfile.code"
                    />
                  </div>
                </div>
                <!-- <div class="col-md-6">
                                <div class="form-group mb-3 pb-md-3">
                                    <label class="mb-1">Gender</label>
                                    <select name="gender" class="form-control" [(ngModel)]="userProfile.gender" >
                                        <option value="" selected>Select</option>
                                        <option value="1">Male</option>
                                        <option value="2" selected>Female</option>
                                        <option value="3" selected>Other</option>
                                    </select>

                                </div>
                            </div> -->

                <div class="col-md-6">
                  <div class="form-group mb-3 pb-md-3">
                    <label class="mb-1">Blood Group</label>
                    <input
                      type="text"
                      oninput="this.value = this.value.replace(/[^A-Za-z0-9!-@#$&()\\-`.+,/\ ]|^ /g,'')"
                      value="A+"
                      class="form-control"
                      [(ngModel)]="userProfile.bloodGroup"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3 pb-md-3">
                    <label class="mb-1">Allergies</label>
                    <input
                      type="text"
                      oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')"
                      value="No"
                      class="form-control"
                      [(ngModel)]="userProfile.allergies"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3 pb-md-3">
                    <label class="mb-1">Emergency Contact</label>
                    <input
                      type="text"
                      oninput="this.value = this.value.replace(/[^0-9 ]|^ /g,'')"
                      value="9874563210"
                      class="form-control"
                      [(ngModel)]="userProfile.emergencyContact"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group mb-3 pb-md-3">
                <label class="mb-1">Nationality</label>

                <mat-select-country
                  autocomplete="off"
                  [disabled]="disabled"
                  [(ngModel)]="nationality"
                  appearance="outline"
                  label="Select"
                  (onCountrySelected)="onCountrySelected($event)"
                >
                </mat-select-country>
              </div>

              <div
                class="d-flex justify-content-center w-100 align-items-center my-4"
              >
                <button
                  type="submit"
                  (click)="editProfileInfo(); toggleDisplayDivIf()"
                  name="submit"
                  value="submit"
                  class="btn-lg btnaddVacine widthbtnsignup text-center mr-1"
                  *ngIf="!isShowDivIf"
                >
                  Edit Profile
                </button>
                <button
                  type="button"
                  name="submit"
                  (click)="submitFormData(); toggleDisplayDivIf()"
                  class="btn-lg btnaddVacine widthbtnsignup text-center ml-1"
                  *ngIf="isShowDivIf"
                >
                  Update Info
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- spinner code -->

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  type="ball-spin-clockwise"
>
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
