import { THCRoutes } from "../interfaces/routing.interface";

export const THC_ROUTES: THCRoutes[] = [
  {
    route: 'home',
    title: 'home',
    icon: 'assets/icons/home.svg'
  },
  {
    route: 'my-profile',
    title: 'profile',
    icon: 'assets/icons/profile.png'
  },
  {
    route: 'identification-details',
    title: 'identification details',
    icon: 'assets/icons/identification.png'
  },
  {
    route: 'settings',
    title: 'settings',
    icon: 'assets/icons/settings.png'
  },
  {
    route: 'about-us',
    title: 'about us',
    icon: 'assets/icons/logo.svg'
  },
  {
    route: 'help',
    title: 'help',
    icon: 'assets/icons/help.png'
  }
];
