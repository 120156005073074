import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  signOutData: any;

  constructor(
    public jwtHelper: JwtHelperService,
    public router: Router
  ) {}

  getToken(): String {
    return window.localStorage['token'];
  }

  saveToken(token: String, role: String) {
    window.localStorage['token'] = token;
    window.localStorage['role'] = role;
  }

  setIsLogin() {
    localStorage.setItem('isLoggedin', 'true');
  }

  destroyToken() {
    window.localStorage.removeItem('token');
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  logoutUser() {
    // this.signOut()
    this.destroyToken();
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('role');
  }
}
