<div class="content-wrapper map-main">
  <div class="container">
    <div
      class="wrap_first my-vaccination-container mb-md-5 mb-4"
      style="max-width: 100%"
    >
      <h1 class="page-heading mb-4">Vaccination clinics near me</h1>
      <div class="my-vaccine-card-wrapper bg-white pt-md-4">
        <div class="left-tabs">
          <ul class="list-unstyled left-nav border-0">
            <li class="items-map" *ngFor="let item of SearchData">
              <h4 class="mb-1" *ngIf="item.business_status === 'OPERATIONAL'">
                {{ item.name }}
              </h4>
              <span class="badge badge-secondary">
                <small
                  *ngIf="
                    item.business_status === 'OPERATIONAL' &&
                    item.opening_hours?.open_now === false
                  "
                  class="newclass"
                  >CLOSED</small
                ></span
              >
              <span class="badge badge-secondary">
                <small
                  *ngIf="
                    item.business_status === 'OPERATIONAL' &&
                    item.opening_hours?.open_now === true
                  "
                  class="newclass2"
                  >OPEN</small
                ></span
              >
              <span class="badge badge-secondary">
                <svg-icon
                  *ngIf="item.business_status === 'OPERATIONAL'"
                  class="cursor-pointer"
                  (click)="goToMap(item.place_id, item.formatted_address)"
                  src="assets/icons/direction.svg"
                  [stretch]="true"
                ></svg-icon
              ></span>
              <ngb-rating
                *ngIf="item.business_status === 'OPERATIONAL'"
                [max]="5"
                class="checked"
                [(rate)]="item.rating"
                [readonly]="true"
              >
              </ngb-rating>
            </li>
          </ul>
        </div>
        <div class="right-container pt-0 px-0 px-md-3">
          <div class="mb-3 d-sm-flex">
            <input
              placeholder="Type location to find vaccine clinics"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              class="mr-3 form-control"
              #search
              [formControl]="searchControl"
            />
            <button
              (click)="combinedCall()"
              class="mt-2 mt-sm-0 px-4 btn btn-commonbtn button-primary custom-btn mx-auto"
            >
              Get Vaccine Clinics
            </button>
          </div>

          <div class="">
            <div class="">
              <!-- <agm-map
                #gm
                [latitude]="latitude"
                [longitude]="longitude"
                [scrollwheel]="false"
                [zoom]="zoom"
              >
                <agm-marker
                  *ngFor="let item of SearchData"
                  (mouseOver)="onMouseOver(infoWindow, gm)"
                  (mouseOut)="onMouseOut(infoWindow, gm)"
                  (markerClick)="
                    clickedMarker(
                      item.geometry.location.lat,
                      item.geometry.location.lng
                    )
                  "
                  [latitude]="item.geometry.location.lat"
                  [longitude]="item.geometry.location.lng"
                >
                  <agm-info-window [closeOnMapClick]="true" #infoWindow>
                    <div>
                      {{ item.name }}
                      <span class="badge badge-secondary">
                        <small
                          *ngIf="
                            item.business_status === 'OPERATIONAL' &&
                            item.opening_hours?.open_now === false
                          "
                          class="newclass"
                          >CLOSE</small
                        ></span
                      >
                      <span class="badge badge-secondary">
                        <small
                          *ngIf="
                            item.business_status === 'OPERATIONAL' &&
                            item.opening_hours?.open_now === true
                          "
                          class="newclass2"
                          >OPEN</small
                        ></span
                      >
                    </div>
                    <div>
                      <ngb-rating
                        *ngIf="item.business_status === 'OPERATIONAL'"
                        [max]="5"
                        class="checked"
                        [(rate)]="item.rating"
                        [readonly]="true"
                      >
                      </ngb-rating>
                      <a class="btn btn-attention pull-right"
                        ><span>
                          <svg-icon
                            *ngIf="item.business_status === 'OPERATIONAL'"
                            class="cursor-pointer"
                            (click)="
                              goToMap(item.place_id, item.formatted_address)
                            "
                            src="assets/icons/direction.svg"
                            [stretch]="true"
                          ></svg-icon></span
                      ></a>
                    </div>
                  </agm-info-window>
                </agm-marker>

                <agm-marker
                  [latitude]="latitude"
                  [longitude]="longitude"
                ></agm-marker>
              </agm-map> -->

              <google-map
                height="600px"
                width="100%"
                [center]="center"
                [zoom]="4"
              >
              </google-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  type="ball-spin-clockwise"
>
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
