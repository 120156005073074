<!-- login header -->
<div class="header-wrapper">
  <div
    class="container py-md-3 py-2"
    *ngIf="!isLogedin && router.url !== '/about-us'"
  >
    <div class="row mx-0">
      <div class="col-12 px-0">
        <div class="d-flex align-items-center justify-content-between">
          <a (click)="Back()" class="logo_design cursor-pointer">
            <!-- <img src="" alt="logo" class="img-fluid"> -->
            <svg-icon src="assets/img/logo.svg" [stretch]="true"></svg-icon>
            <span>Travel Health Companion</span>
          </a>
          <a *ngIf="!resetheader" [routerLink]="['/login']" class="btndesign">
            <span>Sign in </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- left panel start-->
  <!-- [style.height.px] = "screenHeight" -->
  <app-side-panel
    *ngIf="isLogedin && router.url !== '/about-us'"
  ></app-side-panel>
  <!--  -->
  <!-- left panel start-->
  <!-- After login header -->
  <ng-container *ngIf="isLogedin">
    <div class="container py-md-3 py-2">
      <div class="row mx-0">
        <div class="col-12 px-0">
          <div class="wrapperHeader d-none d-md-flex">
            <div class="headerFirst cursor-pointer" (click)="goToHome()">
              <a href="javascript:void(0)" class="logo_design">
                <img
                  src="assets/img/logo_mobile.png"
                  alt="logo"
                  class="img-fluid"
                />
                <span class="d-none d-sm-inline-block">
                  Travel Health Companion
                </span>
              </a>
            </div>
            <div class="headerSecond">
              <div class="btn-group user-profile h-auto w-auto" dropdown>
                <button
                  class="btn btn-outline-primary"
                  (click)="collapseNavbar1(); showNotificationData()"
                  dropdownToggle
                >
                  <a
                    *ngIf="
                      notification === undefined ||
                      notification.length == 0 ||
                      filterData?.length == 0
                    "
                    href="javascript:void(0)"
                    class="d-block"
                    ><img src="assets/icons/bell.svg" alt="logo" class="w-100"
                  /></a>
                  <a
                    *ngIf="filterData?.length > 0"
                    href="javascript:void(0)"
                    class="notification p-0"
                    ><img src="assets/icons/bell.svg" alt="logo" />
                    <span class="badge">{{ filterData.length }}</span></a
                  >
                </button>
                <div
                  *dropdownMenu
                  class="dropdown-menu dropdown-menu-right p-0 border-0"
                >
                  <div class="custom-drop custom-notification">
                    <a
                      routerLink="/#"
                      (click)="hideNav()"
                      *ngFor="let item of notification"
                    >
                      <div class="d-flex justify-content-between">
                        <h3 class="mb-0">{{ item.diseaseName }}</h3>
                        <span>{{ item.created | amTimeAgo }}</span>
                      </div>
                      <p class="mb-0">{{ item.msg }}</p>
                    </a>
                    <div *ngIf="notification?.length > 0" class="text-center">
                      <a routerLink="/notification" (click)="hideNav()"
                        >View All</a
                      >
                    </div>
                    <div *ngIf="notification?.length === 0">
                      <div class="d-flex justify-content-between">
                        <p class="text-center mb-0 p-3">
                          No Notification Received
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-group user-profile" dropdown>
                <div class="user-profile">
                  <button class="btn btn-outline-primary" dropdownToggle>
                    <a
                      *ngIf="img === undefined"
                      href="#"
                      class="user-pics d-block"
                      ><img src="assets/img/user.png" alt="logo" class="w-100"
                    /></a>
                    <a
                      *ngIf="img !== undefined"
                      href="#"
                      class="d-block user-pics"
                      ><img src="{{ profilePic }}" alt="logo"
                    /></a>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu custom-drop">
                    <li role="menuitem" routerLink="/my-profile">
                      <a class="dropdown-item cursor-pointer">My Profile</a>
                    </li>
                    <li role="menuitem" routerLink="/identification-details">
                      <a class="dropdown-item cursor-pointer"
                        >Identification Details</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <span style="cursor: pointer" (click)="onLoggedout()"
                >Sign Out</span
              >
            </div>
          </div>

          <div class="mobile-navbar d-md-none">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <div
                class="d-flex align-items-center justify-content-between"
                [routerLink]="['/']"
              >
                <a href="javascript:void(0)" class="logo_design cursor-pointer">
                  <img
                    src="assets/img/logo_mobile.png"
                    alt="logo"
                    class="img-fluid"
                  />
                  <span>Travel Health Companion</span>
                </a>
              </div>
              <div
                [ngbCollapse]="isMenuCollapsed"
                class="pt-1 mt-1 border-top collapse navbar-collapse"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <!-- Step 4: Close the menu when a link is clicked. -->
                    <a
                      class="nav-link"
                      (click)="isMenuCollapsed = true; GoToDetails()"
                    >
                      Home
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="role === '2'">
                    <a
                      class="nav-link"
                      [routerLink]="['/crisis-line']"
                      (click)="isMenuCollapsed = true"
                    >
                      Crisis Line
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [routerLink]="['/my-vaccination/all']"
                      (click)="isMenuCollapsed = true"
                    >
                      My Vaccine Record
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      (click)="
                        isMenuCollapsed = true;
                        triggerModal4(modalData4, result)
                      "
                    >
                      What vaccines do I nee d to travel?
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [routerLink]="'/vaccineClinics'"
                      (click)="isMenuCollapsed = true"
                    >
                      Vaccination Clinics near me
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [routerLink]="['/travel-health-info']"
                      (click)="isMenuCollapsed = true"
                    >
                      Travel Tips
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [routerLink]="['/my-profile']"
                      (click)="isMenuCollapsed = true"
                    >
                      My Profile
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [routerLink]="['/identification-details']"
                      (click)="isMenuCollapsed = true"
                    >
                      Identification Details
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [routerLink]="['/notification']"
                      (click)="isMenuCollapsed = true"
                    >
                      Notification
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      style="cursor: pointer"
                      (click)="onLoggedout()"
                      routerLink="/"
                    >
                      Sign Out</a
                    >
                  </li>
                  <li class="nav-item" *ngIf="!isLogedin && !resetheader">
                    <a class="btndesign" [routerLink]="['/login']">
                      <span> Sign in </span>
                    </a>
                  </li>
                </ul>
              </div>
              <button
                class="navbar-toggler"
                type="button"
                (click)="isMenuCollapsed = !isMenuCollapsed"
              >
                &#9776;
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="sidebar-slider" [class.sidebar-slide-in]="sidebarShow">
      <div class="sidebar-close" (click)="sidebarShow = !sidebarShow">
        <span><img src="assets/img/close_img.png" class="img-fluid" /></span>
      </div>
      <div class="sidebar-content">
        <app-sidebar></app-sidebar>
      </div>
    </div> -->
  </ng-container>
</div>

<ng-template #modalData4 let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Confirmation</h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body p-3 text-center">
    <h3>
      You are leaving the Travel Health Companion app and will be redirected to
      an external webpage called fit4travel, the information given is not the
      responsibility of Travel Health Companion. Want to continue?
    </h3>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn button-primary-outline px-5"
      (click)="modal.close('Save click')"
    >
      No
    </button>
    <button
      type="button"
      class="btn btn-danger px-5"
      (click)="ConfirmnextDose(locationData); modal.close('Save click')"
    >
      Yes
    </button>
  </div>
</ng-template>
