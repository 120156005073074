import { Component } from '@angular/core';

import { THC_ROUTES } from '../constants/routes.constant';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent {
  public readonly THCRoutes = THC_ROUTES;
}
