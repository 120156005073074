
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  public currentLang: any;

  constructor(
    public activatedRoute: ActivatedRoute, 
    
  ) {
    
    
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    const authRequest = request.clone({
      headers: request.headers.set('authorization', 'Basic dHJhdmVsX2NvbXBhbmlvbjp0cmF2ZWxfY29tcGFuaW9u '),
   
       
    });

    return next.handle(authRequest);
  }
}
