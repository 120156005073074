import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-m',
  templateUrl: './about-m.component.html',
  styleUrls: ['./about-m.component.scss']
})
export class AboutMComponent implements OnInit {
  base_url = `https://app.termly.io/document/terms-of-service/48cb0a35-e9b1-4783-b1b9-6fa0417fc342`
  baseUrl =  `https://app.termly.io/document/privacy-policy/7b1a012a-5da0-418e-9db2-a3bd10e120f1`
  facebookbase_url = `https://www.facebook.com/TravelHealthCompanion`
  instragrambase_url = `https://www.instagram.com/travelhealth_companion`
  linkedinbase_url = `https://www.linkedin.com/company/77583164/admin/`
  token: any;
  date = new Date();
  constructor(private router: Router,) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
  }

  GotoLiveUrl(){
   window.open(
    this.base_url, "_blank");
  }
  GotoLiveUrlPrivacy(){
   window.open(
    this.baseUrl, "_blank");
  }
  GotolinkedinUrl(){
    window.location.href = this.linkedinbase_url
  }
  GotoinstragramUrl(){
    window.location.href = this.instragrambase_url
  }
  GotofacebookUrl(){
    window.location.href = this.facebookbase_url
  }
  selectAccount() {
    this.router.navigate([
      "/signup",
      {
        accounttype: 'Individual',
      },
    ]);
  }
  selectOrginationAccount(){
    this.router.navigate([
      "/signup",
      {
        accounttype: 'Company',
      },
    ]);
  }
}
