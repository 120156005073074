import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Injectable({
  providedIn: 'root'
})
export class ChangeBehavoiorCssService {

private dataSource = new BehaviorSubject<number>(-1);
data = this.dataSource.asObservable();

constructor() { }

updatedDataSelection(data: number){
  this.dataSource.next(data);
}

updatedDataSelectionuserProfile(data: any){
 
  this.dataSource.next(data);
}


}
