import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-m',
  templateUrl: './terms-m.component.html',
  styleUrls: ['./terms-m.component.scss'],
})
export class TermsMComponent implements OnInit {
  appname = '{{appname}}';
  country = '{{country}}';
  constructor() {}
  ngOnInit(): void {}
}
