import { Component, OnInit } from '@angular/core';
import {Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  token: any;
  base_url = `https://app.termly.io/document/terms-of-service/48cb0a35-e9b1-4783-b1b9-6fa0417fc342`
  baseUrl =  `https://app.termly.io/document/privacy-policy/7b1a012a-5da0-418e-9db2-a3bd10e120f1`
  facebookbase_url = `https://www.facebook.com/TravelHealthCompanion`
  instragrambase_url = `https://www.instagram.com/travelhealth_companion`
  linkedinbase_url = `https://www.linkedin.com/company/77583164/admin/`
  signupwith: any;
  facebookData: any;
  imageSrc1 = "../../../assets/img/who-are-we.png"
  imageSrc2 = "assets/img/our-mission.png"
  imageSrc3 = "assets/img/our-motto.png"
  imageSrc4 = "assets/img/our-vision.png"
  imageSrc5 = "../../../assets/img/james-smith.png"
  imageSrc6 = "../../../assets/img/melanie-villarreal.png"
  imageSrc7 = "../../../assets/img/ana-marques.png"
  imageSrc8 = "../../../assets/img/supplement-brand.jpg"
  imageSrc9 = "../../../assets/img/mindfullness-brand.jpg"
  imageSrc10 = "../../../assets/img/fitness-brand.jpg"
  imageSrc11 = "../../../assets/img/online-psychologicaly.jpg"
  imageSrc12 = "../../../assets/img/yoga.jpg"
  imageSrc13 = "../../../assets/img/workout-video.jpg"
  imageSrc14 = "../../../assets/img/vaccine-clinic.jpg"
  imageSrc15 = "../../../assets/img/life-coach.jpg"
  imageSrc16 = "../../../assets/img/travel-insurance-company.webp"
  imageSrc17 = "assets/img/google-play.png"
  imageSrc18 = "assets/img/app-strore.png"
  playStorebaseUrl =  `https://play.google.com/store/apps/details?id=com.app.travelhealthcompanion`
  appleStorebaseUrl = `https://apps.apple.com/in/app/travel-health-companion/id1604773881`

  linkedInToken: any;
  date = new Date();

  constructor(
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
   this.token = localStorage.getItem('token')
  }

  GotoLiveUrl(){
   window.open(
    this.base_url, "_blank");
  }

  GotoLiveUrlPrivacy(){
   window.open(
    this.baseUrl, "_blank");

  }
  selectAccount() {
    this.router.navigate([
      "/signup",
      {
        accounttype: 'Individual',
      },
    ]);
  }
  selectOrginationAccount(){
    this.router.navigate([
      "/signup",
      {
        accounttype: 'Company',
      },
    ]);
  }

  GotoLiveUrlplayStore(){
    // this.toastr.success('Coming soon in the next few days, but in the meantime please do sign up via the sign-up button on the homepage.');
    window.open(
      this.playStorebaseUrl, "_blank");
  }

  GotoLiveUrlappleStore(){
    window.open(
      this.appleStorebaseUrl, "_blank");
  }
  GotolinkedinUrl(){

    window.location.href = this.linkedinbase_url

  }

  GotoinstragramUrl(){
    window.location.href = this.instragrambase_url

  }
  GotofacebookUrl(){
    window.location.href = this.facebookbase_url

  }

}
