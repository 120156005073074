<div style="max-height: 100vh">
  <app-header *ngIf="router.url | rootScreens : 'header'"></app-header>

  <div [ngClass]="{ 'widthout-login': isLogout, 'login-wrapper': !isLogout }">
    <div class="d-flex flex-column h-100">
      <div class="flex-1">
        <router-outlet></router-outlet>
      </div>
      <app-footer *ngIf="router.url | rootScreens : 'footer'"></app-footer>
    </div>
  </div>
</div>
