import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crisis-line',
  templateUrl: './crisis-line.component.html',
  styleUrls: ['./crisis-line.component.scss']
})
export class CrisisLineComponent implements OnInit {

  constructor(
    public readonly router: Router
  ) { }

  ngOnInit(): void {
  }

  public back() {
    this.router.navigate(['/home']);
  }

}
