import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { ApiService } from './services/api.httpservice';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MomentModule } from 'ngx-moment';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { OrdinalDatePipe } from './extras/ordinal-date.pipe';
import { OrdinalPipe } from './extras/ordinal.pipe';
import { HttpTokenInterceptor } from './interceptors';
import { HttpInterceptorService } from './interceptors/http-interceptor.service';
import { environment } from 'src/environments/environment';
import { AuthGuardService } from './services/auth.guard.service';
import { NavigationService } from './services/navigation.services';
import { DataSharingService } from '../shared/services/data-sharing.service';
import { DubleClickDirective } from '../shared/extras/duble-click.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    SidePanelComponent,
    OrdinalDatePipe,
    OrdinalPipe,
    DubleClickDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    NgbCollapseModule,
    NgScrollbarModule,
    AngularSvgIconModule.forRoot(),
    BsDropdownModule.forRoot(),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59,
      },
    }),
  ],
  providers: [
    DataSharingService,
    ApiService,
    SocialAuthService,
    NavigationService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    SidePanelComponent,
    OrdinalDatePipe,
    OrdinalPipe,
    DubleClickDirective,
  ],
})
export class SharedModule {}
