<div class="content-wrapper">
    <div class="container">

      <div class="wrap_first createaccount">
            <div class="dashboard-tiles-wrapper mxw-100 w-100 pt-0 px-md-5">
                <div class="row text-center">
                    <div class="col-md-6 mb-4">
                        <div class="org-list p-4 h-100" [routerLink]="['/']">
                            <div class="org-img mb-4">
                                <img src="../../assets/img/travel-recommendation.jpg" alt="">
                            </div>
                            <h4>Travel Health</h4>
                            <p>Tap here to store & review your vaccinations, search vaccines required & nearby clinics.</p>
                            <a ><svg-icon src="assets/img/right-arrow.svg" [stretch]="true"></svg-icon></a>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="org-list p-4 h-100">
                            <h4>Psychological Health</h4>
                            <div class="org-label mt-5 pt-5">
                                <span>
                                    <small>Coming Soon</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="org-list p-4 h-100">
                            <h4>Physical Health</h4>
                            <div class="org-label mt-5 pt-5">
                                <span>
                                    <small>Coming Soon</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="org-list p-4 h-100">
                            <h4>Our Community</h4>
                            <div class="org-label mt-5 pt-5">
                                <span>
                                    <small>Coming Soon</small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
<app-footer></app-footer>