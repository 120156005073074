<div class="left-panel">
  <ng-scrollbar>
    <ul class="left-navbar list-unstyled mb-0">
      <li *ngFor="let route of THCRoutes">
        <a
          class="cursor-pointer"
          routerLinkActive="active"
          [routerLink]="route.route"
        >
          <img
            [src]="route.icon"
            [ngStyle]="{
              'max-height': route.route !== 'home' ? '34px' : '30px'
            }"
          />
          <span class="ml-3"> {{ route.title | uppercase }} </span>
        </a>
      </li>
    </ul>
  </ng-scrollbar>
</div>
