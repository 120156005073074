import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginDataService {
  private dataSource = new BehaviorSubject<any>('');
  data = this.dataSource.asObservable();

constructor() { }

LoginuserProfile(data: any){
 
  this.dataSource.next(data);
 
}




}