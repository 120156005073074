import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApisService } from 'src/app/shared/services/api.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { NotificationService } from '../../shared/services/notification.service'
import { LoginDataService } from '../../shared/services/login-data.service'

@Component({
  selector: 'app-organisation-home',
  templateUrl: './organisation-home.component.html',
  styleUrls: ['./organisation-home.component.scss']
})
export class OrganisationHomeComponent implements OnInit {
  notification: any;
  filterData: any;
  pageSize = 3;
  page = 1
  userProfile: any;
  userProfileData: any;
  img: any;
  userProfileType: any;
  profilePic: any;
  disableSubmit: any;
  isLogedin: any;

  constructor(
    public router: Router,
    private toastr: ToastrService,
    private apiService: ApisService,
    private jwtService: JwtService,
    private SpinnerService: NgxSpinnerService,
    private NotificationService:NotificationService,
    private LoginDataService :LoginDataService
  ) { }

  ngOnInit(): void {
    this.getNotificationData(this.page,this.pageSize,-1,"")
    this.getInitialData()
  }


  //get notification
  getNotificationData(page:number,pageSize:number,dir:number,searchKey:string,) {

    this.SpinnerService.show();

    this.apiService.getNotification(page,pageSize,dir,searchKey).subscribe((res) => {
      this.notification = res.responseData?.notif
      this.filterData =  this.notification?.filter((art:any) => art.isRead===0);
      this.NotificationService.updatedDatanotification(res.responseData)
    this.SpinnerService.hide();
    });
  
   
  }


  getInitialData(){
     
    this.apiService.getUserProfile().subscribe(res=>{
      if (res.statusCode == 1) {
      this.userProfile=res.responseData?.userProfile;
      this.userProfileData=res.responseData;
      this.img =  res.responseData?.userProfile.img
      this.userProfileType=res.responseData?.userProfile.userType;
     this.profilePic=`${res.responseData?.user_bP}${this.userProfile?.img}`
     this.SpinnerService.hide()
     this.LoginDataService.LoginuserProfile(this.userProfileData);
      }else{
        this.toastr.error(res.error.responseMessage, "Error");
          this.disableSubmit = false;
          localStorage.removeData('token')
          this.jwtService.logoutUser();
          this.isLogedin = false;
          this.router.navigate(['/']);
      }
    })
  
   
  }

}
