import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { MapsAPILoader } from '@agm/core';
import { ApisService } from 'src/app/shared/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'underscore';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @ViewChild('search') private searchElementRef: any;
  public latitude: any;
  public longitude: any;
  selectedLat: Number = 0;
  selectedLng: Number = 0;
  public searchControl: FormControl;
  public zoom: any;
  lat = 37.75;
  lng = -122.41;
  addresses: any[] = [];
  selectedAddress: any = null;
  debuggerounce: any;
  searchText: any;
  SearchData: any;
  //url =`https://www.google.com/maps/place/?q=place_id:`
  url = `https://www.google.com/maps/search/?api=1&query=<address>&query_place_id=<placeId>`;
  openedWindow: any;
  latlngBounds: any;

  public center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };

  constructor(
    private apiService: ApisService,
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private SpinnerService: NgxSpinnerService
  ) {
    this.searchControl = new FormControl();
  }

  getGooglePlacesData() {
    this.SpinnerService.show();
    this.searchText = 'travel+health&vaccinationclinic';
    this.apiService
      .getGooglePlacesApiData(this.searchText, this.latitude, this.longitude)
      .subscribe((data) => {
        this.SearchData = data.responseData?.results;
        this.SpinnerService.hide();
      });
  }
  combinedCall() {
    this.getGooglePlacesData();
    //this.getMapData();
  }
  onMouseOver(infoWindow: any, gm: any) {
    //infoWindow.open();
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  onMouseOut(infoWindow: any, $event: any) {
    setTimeout(() => {
      infoWindow.close();
    }, 3000);
  }
  goToMap(data: any, address: any) {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${address}&query_place_id=${data}`,
      '_blank'
    );
    // window.location.href =  this.url+data
  }

  move(event: any) {}
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  ngOnInit() {
    // this.getMapData();
    this.zoom = 4;
    this.latitude = 33.7886415;
    this.longitude = -118.1611943;
    //create search FormControl
    this.searchControl = new FormControl();
    //set current position
    this.setCurrentPosition();

    //load Places Autocomplete
    // this.mapsAPILoader.load().then(() => {
    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
    //   })
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom

    //      this.latitude = place.geometry.location.lat();
    //      this.longitude = place.geometry.location.lng();

    //       this.zoom = 12;
    //     });
    //   });
    // });
  }

  clickedMarker(lat: number, lng: number) {
    this.latitude = lat;
    this.longitude = lng;
    this.zoom = 15;
  }
}
