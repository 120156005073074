import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LocalServiceService } from '../app/shared/services/local-service';
import { ChangeBehavoiorCssService } from '../app/shared/services/change-behavoior-css';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Travelcomp';
  public isLogout!: boolean;

  constructor(
    private LocalServiceService: LocalServiceService,
    private changeBehavoiorCssService: ChangeBehavoiorCssService,
    public readonly router: Router
  ) { }

  ngOnInit(): void {
    this.isLogout = this.LocalServiceService.getData('token') === null;
    this.changeBehavoiorCssService.data.subscribe((ele: any) => {
      if (ele === 1) {
        this.isLogout = true;
      } else if (ele === 0) {
        this.isLogout = false;
      }
    });
  }
}
