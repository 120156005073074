import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { JwtService } from './jwt.service';
@Injectable()
export class AuthGuardService {
  constructor(public auth: JwtService, public router: Router) {}
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
