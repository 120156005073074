<div class="landing">
    <div class="header-wrapper">
        <div class="container-fluid px-xl-5 px-3 py-md-3 py-2">
            <div class="row mx-0 align-items-center">
                <div class="col-4 px-0" *ngIf= "token===null">
                    <a  [routerLink]="['/']" class="logo_design cursor-pointer">
                        <svg-icon src="assets/img/logo.svg" [stretch]="true"></svg-icon>

                    </a>
                </div>
                <div class="col-8 px-0 text-right">
                    <div class="d-flex justify-content-end">
                        <a *ngIf = "token === null" class="btn-common cursor-pointer d-inline-block mr-md-3 mr-2" [routerLink]="['/signup']" >Sign up /  Sign in</a>
                        <a  *ngIf = "token === null" href="mailto:contactus@travelhealth-companion.com" class="btn-common cursor-pointer btn-fill d-inline-block">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-images">
        <div class="container-fluid px-xl-5 px-3 banner-wrapper">
            <div class="row align-items-center justify-content-center d-sm-flex">
                <div class="col-sm-12 text-center">
                    <div class="pb-md-5 pb-3">
                        <h2 class="heading1 pb-md-4 mb-md-4 mt-md-2 mt-5 pb-2">Travel Health Companion</h2>
                        <p class="mb-4">The ultimate Travel Health Companion for supporting your health and well-being needs when travelling for work or leisure.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div class="your-data px-md-5 px-3 py-md-5 py-3">
        <div class="container-fluid px-xl-5 px-3 pb-md-5">
            <div class="row pt-md-5 pt-3 pb-4">
                <div class="col-md-4 order-2 order-md-1">
                    <div class="why-img text-center mt-4 who-we-img mt-md-0 position-relative">
                        <img [lazyLoad]="imageSrc1" alt="" class="w-100">
                    </div>
                </div>
                <div class="col-md-8 pl-md-5 order-1 order-md-2">
                    <h2 class="heading1 mt-0 pb-2 mb-md-5 mb-3 mt-4 mt-md-0 d-md-inline-block text-md-left text-center">About us</h2>
                    <p class="mb-md-4 mb-3">Travel Health Companion is a mobile application, with the aim to keep those who choose to leave their homeland protected and healthy, no matter where they go.</p>
                    <p class="mb-md-4 mb-3">Travel Health Companion was created by an international aid worker and avid traveller, for international workers and travellers around the world; providing everything you need for travel health and well-being, in one place.</p>
                    <p class="mb-0">Our mobile application is designed with international travellers at the heart of everything, from digitalised vaccine records, searching what vaccinations are required per Country of travel, physical and psychological self-assessments to individualised fitness plans, we are here to support taking care of yourself, everywhere you go.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="wellbeing-section text-center pb-md-5 pb-4">
        <div class="container pb-md-5 pb-3">
                <div class="wellbeing-content px-md-5 py-md-5 px-0 py-4">
                <div class="px-md-4 px-0 pb-md-5">
                <div class="pb-md-5">
                    <h2 class="heading1 mt-0 pb-2 mb-md-5 mb-3 text-center pt-4">Well-being</h2>
                    <p class="mb-md-4 mb-3">An individual’s well-being is vital to an effective and sustainable career or vocation, and this is especially true for international workers.</p>

                    <p class="mb-md-4 mb-3">We have created a platform all international organisations need to support their employees, and individuals to travel safely, whilst assisting their general health and well-being.</p>

                    <p class="mb-md-4 mb-3">Individuals can access physical and psychological resources that allow them to assess their overall well-being on a continuous basis, ensuring their body and mind are remaining healthy and in check during their journey.</p>

                    <p class="mb-md-4 mb-3">Organisations will be able to use the platform to upload tailored content related to staff travel health and the management of their employee’s well-being, whilst abroad.</p>

                    <p class="pb-0 mb-md-4 mb-3">We understand the nature of international travel and will provide access to offline well-being content/support, to travellers with limited internet access, who need it the most.</p>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div class="or-story-ab text-center pb-md-5 pb-4">
        <div class="container pb-md-5 pb-2">
            <div class="mb-md-4 px-md-5 pt-md-5 pt-3">
                <h2 class="heading1 mt-0 pb-2 mb-md-5 mb-3 pt-md-5 p-3">Our Story</h2>
                <p class="mb-md-4 mb-3 pb-2">Melanie Villarreal, Founder of Travel Health Companion, is a Qualified Nurse and experienced Travel Health Practitioner, now specialising in Primary Healthcare in Humanitarian Emergencies.</p>
                <p class="mb-md-4 mb-3 pb-2">Having worked in many humanitarian projects, Melanie knows first-hand how long periods away from home, a stressful work environment, and lack of access to usual coping mechanisms, can adversely affect one’s health.</p>

                <h4 class="mb-md-4 mb-3 pb-2">“I have witnessed friends and colleagues deal with, and also personally dealt with, severe burnout doing a job we loved; and realised the hard way how detrimental a lack of resources, support and insight are to an individual’s ability to function and stay present, not only at work, but within society, as a person”</h4>

                <p class="mb-md-4 mb-3 pb-2">These experiences inspired Melanie to create a platform for individuals to have the tools and resources necessary to prevent physical and psychological decline whilst travelling and working abroad, and for organisations to actively support their employees’ well-being.</p>

                <p class="mb-md-4 mb-3 pb-2">Due to the pandemic, travel presents new risks and increased stress. Travellers, now more than ever, need guidance on required vaccinations, a digital record of their vaccine cards and general travel health & wellness support.</p>
                <p class="mb-md-4 mb-3 pb-2">What resulted is the creation of Travel Health Companion.</p>
            </div>
        </div>
    </div>
    <div class="three-column bg-white pt-5 pb-md-5">
        <div class="container py-md-3">
            <div class="row">
                <div class="col-md-4 mb-4 mb-md-0">
                    <div class="list-shadow">
                        <span>
                            <img [lazyLoad]="imageSrc2" class="w-100" />
                        </span>
                        <div class="px-md-4 mx-3 mb-3 mb-md-4">
                            <h4 class="mt-md-4 mt-3 mb-md-3 mb-2">Our Mission</h4>
                            <p class="mb-0">To provide a safe platform, whilst travelling, for individuals to take care of their health and organisations to support their well being.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4 mb-md-0">
                    <div class="list-shadow">
                        <span>
                            <img [lazyLoad]="imageSrc3" class="w-100"/>
                        </span>
                        <div class="px-md-4 mx-3 mb-3 mb-md-4">
                            <h4 class="mt-md-4 mt-3 mb-md-3 mb-2">Our Motto</h4>
                            <p class="mb-0">Support yourself first, so you can support others.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4 mb-md-0">
                    <div class="list-shadow">
                        <span>
                            <img [lazyLoad]="imageSrc4" class="w-100" />
                        </span>
                        <div class="px-md-4 mx-3 mb-3 mb-md-4">
                            <h4 class="mt-md-4 mt-3 mb-md-3 mb-2">Our Vision</h4>
                            <p class="mb-0">To create a healthier and more sustainable workforce, where supporting yourself first is a cultural norm.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="our-team bg-light-blue px-md-5 px-0 py-md-5 py-3">
        <div class="container-fluid px-xl-5 pb-md-5 pb-3 mt-3">
            <h2 class="heading1 mt-0 pb-2 mb-md-5 mb-3 text-center">Our Team</h2>
            <div class="row">
                <div class="col-md-4 mb-sm-5 mb-4 mb-md-0">
                    <div class="team-card">
                        <img [lazyLoad]="imageSrc5" alt="" class="w-100">
                        <div class="px-2">
                            <h3 class="mb-0">James Smith</h3>
                            <p class="mb-0">Physical Health Advisor</p>
                            <p class="mb-0">Content Developer</p>
                            <p class="mb-0">Qualified Personal Trainer</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-sm-5 mb-4 mb-md-0">
                    <div class="team-card">
                        <img [lazyLoad]="imageSrc6"alt="" class="w-100">
                        <div class="px-2">
                            <h3 class="mb-0">Melanie Villarreal</h3>
                            <p class="mb-0">Founder & Humanitarian Aid worker</p>
                            <p class="mb-0">Public Health Specialist</p>
                            <p class="mb-0">Qualified Nurse</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-sm-5 mb-4 mb-md-0">
                    <div class="team-card">
                        <img [lazyLoad]="imageSrc7" alt="" class="w-100">
                        <div class="px-2">
                            <h3 class="mb-0">Ana Marques</h3>
                            <p class="mb-0">Psychological Health Advisor</p>
                            <p class="mb-0">Qualified Psychologist</p>
                            <p class="mb-0">Humanitarian Aid Worker</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid brand-partners bg-white px-md-5 px-3 py-md-5 py-4">
        <div class="container-fluid px-xl-5 px-3 pb-md-5 mt-md-4 pb-3">
            <h2 class="heading1 mt-0 pb-2 mb-md-5 mb-3 text-center">Brand Partners</h2>
        </div>

        <ul class="list-inline mb-0 text-center">
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc8" alt="">
                    </div>
                    <h3 class="mt-3">SUPPLEMENT <br />BRANDS</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc9" lt="">
                    </div>
                    <h3 class="mt-3">MINDFULNESS <br />BRANDS</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc10" alt="">
                    </div>
                    <h3 class="mt-3">FITNESS <br />BRANDS</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc11" alt="">
                    </div>
                    <h3 class="mt-3">PSYCHOLOGISTS</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc12" alt="">
                    </div>
                    <h3 class="mt-3">YOGA <br />PRACTIONERS</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc13" alt="">
                    </div>
                    <h3 class="mt-3">PERSONAL <br />TRAINERS</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc14" alt="">
                    </div>
                    <h3 class="mt-3">VACCINATION <br />CLINICS</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc15" alt="">
                    </div>
                    <h3 class="mt-3">HUMANITARIAN <br />LIFE COACHES</h3>
                </div>
            </li>
            <li class="list-inline-item">
                <div class="brand-card mb-md-5 m-3">
                    <div class="brand-img">
                        <img [lazyLoad]="imageSrc16" alt="">
                    </div>
                    <h3 class="mt-3">TRAVEL INSURANCE <br />COMPANIES</h3>
                </div>
            </li>
        </ul>
    </div>
    <div class="text-center signup-wrrapper signup-wrrapper1 pt-5 d-flex align-items-center w-100 h-100">
        <ul class="list-inline mb-0 w-100">
            <li *ngIf = "token === null" class="list-inline-item mr-0" [routerLink]="['/signup']">
                <h3 class="heading4 mt-4">For Organisations</h3>
                <a href="mailto:contactus@travelhealth-companion.com" class="btn btn-common mr-0 mr-md-3 mr-2 d-flex align-items-center justify-content-center" style="position: relative;top: 1.5px;">Contact us</a>
            </li>
            <li *ngIf = "token === null" class="list-inline-item" [routerLink]="['/signup']">
                <h3 class="heading4 mt-4">For Individuals</h3>
                <button class="btn btn-common">Sign up</button>
            </li>
        </ul>
    </div>
    <footer class="px-md-5 pt-xl-5 pt-4 pb-xl-5 pb-3" >
        <div class="container-fluid px-xl-5 px-3" *ngIf = "token ===null">
            <div class="row">
                <div class="col-md-3 order-1 order-md-1 text-center text-md-left">
                    <a class="logo_design cursor-pointer">
                        <svg-icon src="assets/img/logo.svg" [stretch]="true"></svg-icon>
                        <p><span>Travel Health Companion</span></p>
                    </a>
                    <ul class="list-inline socail-icons mb-md-0 mb-3 mt-4">
                        <li class="list-inline-item" (click)="GotofacebookUrl()" >
                            <svg-icon class="cursor-pointer" src="assets/img/facebook.svg" [stretch]="true"></svg-icon>
                        </li>
                        <li class="list-inline-item" (click)="GotolinkedinUrl()">
                            <svg-icon class="cursor-pointer" src="assets/img/linkedin.svg" [stretch]="true"></svg-icon>
                        </li>
                        <li class="list-inline-item" (click)="GotoinstragramUrl()">
                            <svg-icon class="cursor-pointer" src="assets/img/instagram.svg" [stretch]="true"></svg-icon>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-7 mb-md-3 order-2 order-md-2">
                    <ul class="footer-nav list-unstyled mb-0">
                        <li><a href="/">Home</a></li>
                        <li><a [routerLink]="['/about-us']">About Us</a></li>
                        <li class="cursor-pointer"><a (click)= "GotoLiveUrl()">Terms & Conditions</a></li>
                    </ul>
                </div>
                <div class="col-md-3 col-5 mb-md-3 order-3 order-md-3">
                    <ul class="footer-nav list-unstyled mb-0">
                        <li class="cursor-pointer"><a (click) = "GotoLiveUrlPrivacy()">Privacy Policy</a></li>
                        <li><a href="mailto:contactus@travelhealth-companion.com">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-md-3 text-right text-md-left order-1 order-md-3">
                    <div class="stores-apps py-4 mb-4 mb-md-0 pt-md-0">
                        <ul class="list-inline d-flex align-items-center mb-0 justify-content-center">
                            <li class="list-inline-item">
                                <a (click) = "GotoLiveUrlplayStore()"><img [lazyLoad]="imageSrc17" class="w-100" /></a>
                            </li>
                            <li class="list-inline-item">
                                <a (click) = "GotoLiveUrlappleStore()"><img [lazyLoad]="imageSrc18" class="w-100" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid px-xl-5 px-3 mt-4 pb-xl-5 mb-xl-3">
            <hr class="my-0">
            <div class="footer-copyright py-3">
            <div class="row">
                <div class="col-md-12 text-center"><a href="#">Copyright @ {{date| date:'yyyy'}} Travel Health Companion. All rights reserved</a></div>
            </div>
          </div>
        </div>
    </footer>
</div>
