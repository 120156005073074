<div class="content-wrapper">
  <div class="container">
    <div class="wrap_first createaccount pb-4">
      <div
        class="d-flex flex-column justify-content-center align-items-start h-100 mx-auto gap-2 gap-lg-4 crisis-wrapper"
      >
        <span class="ps-0 ps-md-3 ps-lg-5">
          <a class="crisis-mobile" href="tel:+447861628750">
            <img
              src="assets/icons/mobile-phone.png"
              alt="phone"
              height="41px"
              width="25px"
            />
            +44 7861 628750
          </a>
        </span>
        <span class="crisis-mobile">
          to speak a psychological health professional immediately click the
          following number
        </span>
      </div>
    </div>
  </div>
</div>
