<div class="wrap_both p-3">
  <a href="#" class="borderline"> Dashboard </a>
  <a href="#">
    <img src="assets/img/nexticon.png" alt="nexticon" class="img-fluid" />
  </a>
</div>
<div class="wrap_both p-3">
  <a [routerLink]="['/my-vaccination/all']" class="borderline">
    My Vaccine Record
  </a>
  <a href="#">
    <img src="assets/img/nexticon.png" alt="nexticon" class="img-fluid" />
  </a>
</div>
<div class="wrap_both p-3">
  <a [routerLink]="['/vaccineClinics']" class="borderline">
    Vaccination Clinics near me
  </a>
  <a [routerLink]="['/vaccineClinics']">
    <img src="assets/img/nexticon.png" alt="nexticon" class="img-fluid" />
  </a>
</div>
<div class="wrap_both p-3">
  <a class="borderline"> What vaccines do I need to travel? </a>
  <a href="#">
    <img src="assets/img/nexticon.png" alt="nexticon" class="img-fluid" />
  </a>
</div>
<div class="wrap_both p-3">
  <a [routerLink]="['/travel-health-info']" class="borderline">
    Travel Health Info
  </a>
  <a href="#">
    <img src="assets/img/nexticon.png" alt="nexticon" class="img-fluid" />
  </a>
</div>
