import { Pipe, PipeTransform } from '@angular/core';

const headerExclusions: string[] = [
  'user',
  'privacy-policies',
  'terms-conditions',
  'helps',
  'about',
];

const footerExclusions: string[] = [
  'privacy-policies',
  'terms-conditions',
  'helps',
  'about',
  'organisation',
];

@Pipe({ name: 'rootScreens' })
export class RootScreensPipe implements PipeTransform {
  public transform = (url: string, component: 'header' | 'footer'): boolean => {
    const exclusions = component === 'header' ? headerExclusions : footerExclusions;

    return url !== '/' && !exclusions.some((exclusion) => url.includes(exclusion));
  };
}
