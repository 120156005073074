import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalServiceService {
  constructor() {}

  setData(key: any, value: any) {
    localStorage.setItem(key, value);
  }

  getData(key: string) {
    return localStorage.getItem(key);
  }

  getJsonPerseData(key: any) {
    return JSON.parse(localStorage.getItem(key) || '[]');
  }

  removeData(key: any) {
    localStorage.removeItem(key);
  }
}
